.box {
  padding: 12px 8px;
  border-radius: 14px;
  font-weight: bold;
  align-items: center;
  background-color: #e8e8e8;
}

.text {
  color: #243474;
}

.num {
  color: #f46423;
}
