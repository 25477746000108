.accordion {
  margin: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.accordion::before {
  margin: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordionDetails {
  padding: 0 !important;
  width: 100%;
  background-color: #1a244c;
}

.rights {
  margin-top: 4px;
  padding: 0 10px;
  font-size: 12px;
  display: flex;
  text-align: justify;
  justify-content: center;
}

.footer {
  width: 80px;
  height: 46px;
  object-fit: cover;
  object-position: 0%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}