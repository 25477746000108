.dialog-title-container {
  padding: 4px 12px 4px 24px !important;
}

.dialog-title {
  display: flex !important;
  align-items: center !important;
}

.flex-container {
  align-items: flex-end;
}
