#content {
  padding: 16px;
  transition: 0.5s;
  width: 50%;
  flex-grow: 1;
}

#loading {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
