#user-area {
  text-align: right;
  margin: 8px 16px;
  width: auto;
  display: flex;
  align-items: center;
}

#avatar {
  width: 32px;
  height: 32px;
  font-size: 16px;
}

#right-menu {
  display: flex;
  cursor: pointer;
  align-items: center;
}