th.kpt-header-cell {
  padding: 0;
  padding-right: 4px;
}

div.kpt-sort-icon {
  flex-grow: 1;
}

div.kpt-sort-icon:hover, div.kpt-search-icon:hover {
  cursor: pointer;
  outline: none;
  background-color: #e6e6e6;
}

div.kpt-search-icon {
  padding: 0 4px;
}

th.MuiTableCell-stickyHeader {
  left: auto;
}

td.kpt-cell-small {
  padding: 4px;
}
