.left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.detailButton {
  color: #fff;
  cursor: pointer;
  font-size: 14px !important;
  margin: 4px 16px 0 16px !important;
}

.box {
  position: absolute;
  top: -1px;
  right: -1px;
  border-top-right-radius: 4px;
  padding: 2px 8px 2px 32px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 32px 100%);
  background-color: #bcbcbc;
  color: #fff;
}

.textfield_b52f2f > fieldset { 
  border-color: #b52f2f !important;
  box-shadow: #b52f2fc7 0px 2px 4px -1px, #b52f2ff2 0px 2px 4px -1px;
}

/* .textfield_243474 > fieldset {
  border-color: #243474 !important;
  box-shadow: #243474c7 0px 2px 4px -1px, #243474f2 0px 2px 4px -1px;
} */

.textfield_f46423 > fieldset {
  border-color: #f46423 !important;
  box-shadow: #f46423c7 0px 2px 4px -1px, #f46423f2 0px 2px 4px -1px;
}