.box {
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
}

.button {
  padding: 0 1px;
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: -6px 0;
}

.button:hover { outline: none; background-color: #e6e6e6; }

.stickyColumnHeader, .stickyColumn {
  position: sticky !important;
  /* left: 0 !important; */
  border-right: solid 1px #e6e6e6;
}

.stickyColumnHeader {
  z-index: 4 !important;
}

.stickyColumn {
  background-color: #fff;
  z-index: 3 !important;
}