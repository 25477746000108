.left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.detailButton {
  color: #fff;
  cursor: pointer;
  font-size: 14px !important;
  margin: -4px 16px 0 16px !important;
}

.box {
  position: absolute;
  top: -1px;
  right: -1px;
  border-top-right-radius: 4px;
  padding: 2px 8px 2px 32px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 32px 100%);
  background-color: #bcbcbc;
  color: #fff;
}