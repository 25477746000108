.header {
  display: flex;
  align-items: center;
  background-color: #ececec;
  border-bottom: solid 1px #bcbcbc;
  padding: 8px 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

/* .files input {
  padding: 5.5px 8px 10.5px !important;
} */